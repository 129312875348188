<template>
  <main id="ShopConfig">
    <CContainer>
      <CTabs variant="pills" :active-tab="0" addNavWrapperClasses="card" addNavClasses="card-body p-2">
        <CTab :title="$t('MerchantData.Payment')">
          <template #title>
            {{ $t('MerchantData.Payment') }}
          </template>
          <CRow class="border-bottom mb-4">
            <CCol col="12" md="6" xl="4">
              <div class="p-3">
                <h3 class="font-weight-bold">TapPay</h3>
                <p>無跳轉支付方式，可增加訂單轉換率。啟用並填入串接資訊後即可使用服務。</p>
                <ul class="pl-3">
                  <li>付款方式: 信用卡(一般/分期)、Apple Pay、LINE Pay</li>
                  <li>更多支援付款方式及資訊請至<a href="https://www.tappaysdk.com" target="_blank" class="text-info">TapPay</a>官網查看。</li>
                </ul>
              </div>
            </CCol>
            <CCol col="12" md="6" xl="8">
              <CCard>
                <CCardBody>
                  <div class="form-group">
                    <label for="tappayEnable" class="d-block">啟用TapPay付款</label>
                    <CSwitch id="tappayEnable" size="sm" color="success" :checked.sync="MerchantData['tappay'].Enable" />
                  </div>
                  <template v-if="MerchantData['tappay'].Enable">
                    <CInput id="tappayMerchantID" v-model="MerchantData['tappay'].MerchantID" size="sm" type="text">
                      <template #label>
                        <label for="tappayMerchantID" class="d-block">{{ $t('MerchantData.MerchantID') }}</label>
                      </template>
                    </CInput>
                    <CInput id="tappayMGID" description="可用於整合TapPay內所有的付款方式與通道，商戶號及MGID擇一填寫即可，若商戶號及MGID皆有填寫則系統會優先使用MGID進行付款。" v-model="MerchantData['tappay'].HashKey" size="sm" type="text">
                      <template #label>
                        <label for="tappayMGID" class="d-block">MGID</label>
                      </template>
                    </CInput>
                    <CInput id="tappayAppID" v-model="MerchantData['tappay'].Account" size="sm" type="text">
                      <template #label>
                        <label for="tappayAppID" class="d-block">App ID</label>
                      </template>
                    </CInput>
                    <CInput id="tappayAppKey" v-model="MerchantData['tappay'].Password" size="sm" type="text">
                      <template #label>
                        <label for="tappayAppKey" class="d-block">App Key</label>
                      </template>
                    </CInput>
                    <CInput id="tappaySecureKey" v-model="MerchantData['tappay'].SecureKey" size="sm" type="text">
                      <template #label>
                        <label for="tappaySecureKey" class="d-block">{{ $t('MerchantData.SecureKey') }}</label>
                      </template>
                    </CInput>
                    <CInput id="tappayApplePayMerchantID" v-model="MerchantData['tappay'].PublicKey" size="sm" type="text">
                      <template #label>
                        <label for="tappayApplePayMerchantID" class="d-block">Apple Pay MerchantID</label>
                      </template>
                    </CInput>
                    <div class="form-group">
                      <label for="tappayTestMode" class="d-block">{{ $t('MerchantData.TestMode') }}</label>
                      <CSwitch id="tappayTestMode" size="sm" color="success" :checked.sync="MerchantData['tappay'].TestMode" />
                    </div>
                  </template>
                  <CCard>
                    <CCardBody class="d-flex justify-content-between p-2">
                      <CButton color="success" class="px-4" @click="SetPayCenterMerchant('tappay')">
                        {{ $t('Global.Update') }}
                      </CButton>
                    </CCardBody>
                  </CCard>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow class="border-bottom mb-4">
            <CCol col="12" md="6" xl="4">
              <div class="p-3">
                <h3 class="font-weight-bold">綠界科技 ECPay</h3>
                <p>綠界科技同時集成金物流與電子發票，適合一般賣家直接使用。</p>
                <ul class="pl-3">
                  <li>付款方式: 信用卡(一般/分期/定期定額)、虛擬帳戶、WebATM、超商代碼、超商條碼</li>
                </ul>
              </div>
            </CCol>
            <CCol col="12" md="6" xl="8">
              <CCard>
                <CCardBody>
                  <div class="form-group">
                    <label for="ecpayEnable" class="d-block">啟用綠界付款</label>
                    <CSwitch id="ecpayEnable" size="sm" color="success" :checked.sync="MerchantData['ecpay'].Enable" />
                  </div>
                  <template v-if="MerchantData['ecpay'].Enable">
                    <CInput id="ecpayPaymentMerchantID" v-model="MerchantData['ecpay'].MerchantID" size="sm" type="text">
                      <template #label>
                        <label for="ecpayPaymentMerchantID" class="d-block">{{ $t('MerchantData.MerchantID') }}</label>
                      </template>
                    </CInput>
                    <CInput id="ecpayPaymentHashKey" v-model="MerchantData['ecpay'].HashKey" size="sm" type="text">
                      <template #label>
                        <label for="ecpayPaymentHashKey" class="d-block">{{ $t('MerchantData.HashKey') }}</label>
                      </template>
                    </CInput>
                    <CInput id="ecpayPaymentHashIV" v-model="MerchantData['ecpay'].HashIV" size="sm" type="text">
                      <template #label>
                        <label for="ecpayPaymentHashIV" class="d-block">{{ $t('MerchantData.HashIV') }}</label>
                      </template>
                    </CInput>
                    <div class="form-group">
                      <label for="ecpayTestMode" class="d-block">{{ $t('MerchantData.TestMode') }}</label>
                      <CSwitch id="ecpayTestMode" size="sm" color="success" :checked.sync="MerchantData['ecpay'].TestMode" />
                    </div>
                  </template>
                  <CCard>
                    <CCardBody class="d-flex justify-content-between p-2">
                      <CButton color="success" class="px-4" @click="SetPayCenterMerchant('ecpay')">
                        {{ $t('Global.Update') }}
                      </CButton>
                    </CCardBody>
                  </CCard>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow class="border-bottom mb-4">
            <CCol col="12" md="6" xl="4">
              <div class="p-3">
                <h3 class="font-weight-bold">藍新金流 NewebPay</h3>
                <p>啟用並填入串接資訊後即可使用服務。</p>
                <ul class="pl-3">
                  <li>付款方式: 信用卡(一般/分期)、虛擬帳戶、WebATM、超商代碼、超商條碼</li>
                </ul>
              </div>
            </CCol>
            <CCol col="12" md="6" xl="8">
              <CCard>
                <CCardBody>
                  <div class="form-group">
                    <label for="newebpayEnable" class="d-block">啟用藍新金流</label>
                    <CSwitch id="newebpayEnable" size="sm" color="success" :checked.sync="MerchantData['newebpay'].Enable" />
                  </div>
                  <template v-if="MerchantData['newebpay'].Enable">
                    <CInput id="newebpayMerchantID" v-model="MerchantData['newebpay'].MerchantID" size="sm" type="text">
                      <template #label>
                        <label for="newebpayMerchantID" class="d-block">{{ $t('MerchantData.MerchantID') }}</label>
                      </template>
                    </CInput>
                    <CInput id="newebpayHashKey" v-model="MerchantData['newebpay'].HashKey" size="sm" type="text">
                      <template #label>
                        <label for="newebpayHashKey" class="d-block">{{ $t('MerchantData.HashKey') }}</label>
                      </template>
                    </CInput>
                    <CInput id="newebpayHashIV" v-model="MerchantData['newebpay'].HashIV" size="sm" type="text">
                      <template #label>
                        <label for="newebpayHashIV" class="d-block">{{ $t('MerchantData.HashIV') }}</label>
                      </template>
                    </CInput>
                    <div class="form-group">
                      <label for="newebpayTestMode" class="d-block">{{ $t('MerchantData.TestMode') }}</label>
                      <CSwitch id="newebpayTestMode" size="sm" color="success" :checked.sync="MerchantData['newebpay'].TestMode" />
                    </div>
                  </template>
                  <CCard>
                    <CCardBody class="d-flex justify-content-between p-2">
                      <CButton color="success" class="px-4" @click="SetPayCenterMerchant('newebpay')">
                        {{ $t('Global.Update') }}
                      </CButton>
                    </CCardBody>
                  </CCard>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow class="border-bottom mb-4">
            <CCol col="12" md="6" xl="4">
              <div class="p-3">
                <h3 class="font-weight-bold">台新銀行 Taishin Bank</h3>
                <p>簽約服務，啟用時請先確定已與銀行端取得串接資訊。</p>
                <ul class="pl-3">
                  <li>付款方式: 信用卡、信用卡分期</li>
                </ul>
              </div>
            </CCol>
            <CCol col="12" md="6" xl="8">
              <CCard>
                <CCardBody>
                  <div class="form-group">
                    <label for="tspgEnable" class="d-block">啟用台新銀行付款</label>
                    <CSwitch id="tspgEnable" size="sm" color="success" :checked.sync="MerchantData['tspgpay'].Enable" />
                  </div>
                  <template v-if="MerchantData['tspgpay'].Enable">
                    <CInput id="tspgMerchantID" v-model="MerchantData['tspgpay'].MerchantID" size="sm" type="text">
                      <template #label>
                        <label for="tspgMerchantID" class="d-block">{{ $t('MerchantData.MerchantID') }}</label>
                      </template>
                    </CInput>
                    <CInput id="tspgHashKey" v-model="MerchantData['tspgpay'].HashKey" size="sm" type="text">
                      <template #label>
                        <label for="tspgHashKey" class="d-block">{{ $t('MerchantData.TerminalID') }}</label>
                      </template>
                    </CInput>
                  </template>
                  <CCard>
                    <CCardBody class="d-flex justify-content-between p-2">
                      <CButton color="success" class="px-4" @click="SetPayCenterMerchant('tspgpay')">
                        {{ $t('Global.Update') }}
                      </CButton>
                    </CCardBody>
                  </CCard>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow class="border-bottom mb-4">
            <CCol col="12" md="6" xl="4">
              <div class="p-3">
                <h3 class="font-weight-bold">財金資訊 FOCAS</h3>
                <p>簽約服務，啟用時請先確定已與銀行端取得串接資訊，適用於台灣銀行、合作金庫銀行、第一銀行收款。</p>
                <ul class="pl-3">
                  <li>付款方式: 信用卡</li>
                </ul>
              </div>
            </CCol>
            <CCol col="12" md="6" xl="8">
              <CCard>
                <CCardBody>
                  <div class="form-group">
                    <label for="focasEnable" class="d-block">啟用財金資訊付款</label>
                    <CSwitch id="focasEnable" size="sm" color="success" :checked.sync="MerchantData['focaspay'].Enable" />
                  </div>
                  <template v-if="MerchantData['focaspay'].Enable">
                    <CInput id="focasMerID" v-model="MerchantData['focaspay'].MerchantID" size="sm" type="text">
                      <template #label>
                        <label for="focasMerID" class="d-block">{{ $t('MerchantData.MerchantID') }}</label>
                      </template>
                    </CInput>
                    <CInput id="focasAccount" v-model="MerchantData['focaspay'].Account" size="sm" type="text">
                      <template #label>
                        <label for="focasAccount" class="d-block">{{ $t('MerchantData.Account') }}</label>
                      </template>
                    </CInput>
                    <CInput id="focasKeyFile" v-model="MerchantData['focaspay'].HashKey" size="sm" type="text">
                      <template #label>
                        <label for="focasKeyFile" class="d-block">{{ $t('MerchantData.TerminalID') }}</label>
                      </template>
                    </CInput>
                    <CInput id="focasMerchantName" v-model="MerchantData['focaspay'].ProjectName" size="sm" type="text">
                      <template #label>
                        <label for="focasMerchantName" class="d-block">{{ $t('MerchantData.MerchantName') }}</label>
                      </template>
                    </CInput>
                  </template>
                  <CCard>
                    <CCardBody class="d-flex justify-content-between p-2">
                      <CButton color="success" class="px-4" @click="SetPayCenterMerchant('focaspay')">
                        {{ $t('Global.Update') }}
                      </CButton>
                    </CCardBody>
                  </CCard>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow class="d-none border-bottom mb-4">
            <CCol col="12" md="6" xl="4">
              <div class="p-3">
                <h3 class="font-weight-bold">LINE Pay</h3>
                <p>啟用並填入串接資訊後即可使用服務。</p>
                <ul class="pl-3">
                  <li>付款方式: LINE Pay</li>
                </ul>
              </div>
            </CCol>
            <CCol col="12" md="6" xl="8">
              <CCard>
                <CCardBody>
                  {{  $t('MerchantData.ComingSoon') }}
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CTab>
        <CTab :title="$t('MerchantData.Logistics')">
          <template #title>
            {{ $t('MerchantData.Logistics') }}
          </template>
          <CRow class="border-bottom mb-4">
            <CCol col="12" md="6" xl="4">
              <div class="p-3">
                <h3 class="font-weight-bold">綠界科技 ECPay</h3>
                <p>綠界科技同時集成金物流與電子發票，適合一般賣家直接使用。</p>
                <ul class="pl-3">
                  <li>四大超商</li>
                  <li>黑貓宅急便</li>
                </ul>
              </div>
            </CCol>
            <CCol col="12" md="6" xl="8">
              <CCard>
                <CCardBody>
                  <div class="form-group">
                    <label for="ecpayLogisticsEnable" class="d-block">啟用綠界物流</label>
                    <CSwitch id="ecpayLogisticsEnable" size="sm" color="success" :checked.sync="MerchantData['ecpay-logistics'].Enable" />
                  </div>
                  <template v-if="MerchantData['ecpay-logistics'].Enable">
                    <CInput id="ecpayLogisticsMerchantID" v-model="MerchantData['ecpay-logistics'].MerchantID" size="sm" type="text">
                      <template #label>
                        <label for="ecpayLogisticsMerchantID" class="d-block">{{ $t('MerchantData.MerchantID') }}</label>
                      </template>
                    </CInput>
                    <CInput id="ecpayLogisticsHashKey" v-model="MerchantData['ecpay-logistics'].HashKey" size="sm" type="text">
                      <template #label>
                        <label for="ecpayLogisticsHashKey" class="d-block">{{ $t('MerchantData.HashKey') }}</label>
                      </template>
                    </CInput>
                    <CInput id="ecpayLogisticsHashIV" v-model="MerchantData['ecpay-logistics'].HashIV" size="sm" type="text">
                      <template #label>
                        <label for="ecpayLogisticsHashIV" class="d-block">{{ $t('MerchantData.HashIV') }}</label>
                      </template>
                    </CInput>
                  </template>
                  <CCard>
                    <CCardBody class="d-flex justify-content-between p-2">
                      <CButton color="success" class="px-4" @click="SetPayCenterMerchant('ecpay-logistics')">
                        {{ $t('Global.Update') }}
                      </CButton>
                    </CCardBody>
                  </CCard>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow class="border-bottom mb-4">
            <CCol col="12" md="6" xl="4">
              <div class="p-3">
                <h3 class="font-weight-bold">新竹物流 HCT</h3>
                <p>簽約服務，啟用時請先確定已與物流供應商取得串接資訊。</p>
                <ul class="pl-3">
                  <li>宅配服務</li>
                </ul>
              </div>
            </CCol>
            <CCol col="12" md="6" xl="8">
              <CCard>
                <CCardBody>
                  <div class="form-group">
                    <label for="hctEnable" class="d-block">啟用新竹物流</label>
                    <CSwitch id="hctEnable" size="sm" color="success" :checked.sync="MerchantData['hct-logistics'].Enable" />
                  </div>
                  <template v-if="MerchantData['hct-logistics'].Enable">
                    <CInput id="hctLogisticsAccount" v-model="MerchantData['hct-logistics'].Account" size="sm" type="text">
                      <template #label>
                        <label for="hctLogisticsAccount" class="d-block">{{ $t('MerchantData.Account') }}</label>
                      </template>
                    </CInput>
                    <CInput id="hctLogisticsPassword" v-model="MerchantData['hct-logistics'].Password" size="sm" type="text">
                      <template #label>
                        <label for="hctLogisticsPassword" class="d-block">{{ $t('MerchantData.Password') }}</label>
                      </template>
                    </CInput>
                    <CInput id="hctLogisticsMerchantID" v-model="MerchantData['hct-logistics'].MerchantID" size="sm" type="text">
                      <template #label>
                        <label for="hctLogisticsMerchantID" class="d-block">{{ $t('MerchantData.MerchantID') }}</label>
                      </template>
                    </CInput>
                  </template>
                  <CCard>
                    <CCardBody class="d-flex justify-content-between p-2">
                      <CButton color="success" class="px-4" @click="SetPayCenterMerchant('hct-logistics')">
                        {{ $t('Global.Update') }}
                      </CButton>
                    </CCardBody>
                  </CCard>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow class="border-bottom mb-4">
            <CCol col="12" md="6" xl="4">
              <div class="p-3">
                <h3 class="font-weight-bold">嘉里大榮物流 KERRY TJ</h3>
                <p>簽約服務，啟用時請先確定已與物流供應商取得串接資訊。</p>
                <ul class="pl-3">
                  <li>宅配服務</li>
                </ul>
              </div>
            </CCol>
            <CCol col="12" md="6" xl="8">
              <CCard>
                <CCardBody>
                  <div class="form-group">
                    <label for="kerrytjEnable" class="d-block">啟用嘉里大榮物流</label>
                    <CSwitch id="kerrytjEnable" size="sm" color="success" :checked.sync="MerchantData['kerrytj-logistics'].Enable" />
                  </div>
                  <template v-if="MerchantData['kerrytj-logistics'].Enable">
                    <CInput id="kerrytjLogisticsAccount" v-model="MerchantData['kerrytj-logistics'].Account" size="sm" type="text">
                      <template #label>
                        <label for="kerrytjLogisticsAccount" class="d-block">{{ $t('MerchantData.Account') }}</label>
                      </template>
                    </CInput>
                    <CInput id="hctLogisticsPassword" v-model="MerchantData['kerrytj-logistics'].Password" size="sm" type="text">
                      <template #label>
                        <label for="kerrytjLogisticsPassword" class="d-block">{{ $t('MerchantData.Password') }}</label>
                      </template>
                    </CInput>
                    <CInput id="kerrytjLogisticsMerchantID" v-model="MerchantData['kerrytj-logistics'].MerchantID" size="sm" type="text">
                      <template #label>
                        <label for="kerrytjLogisticsMerchantID" class="d-block">{{ $t('MerchantData.MerchantID') }}</label>
                      </template>
                    </CInput>
                  </template>
                  <CCard>
                    <CCardBody class="d-flex justify-content-between p-2">
                      <CButton color="success" class="px-4" @click="SetPayCenterMerchant('kerrytj-logistics')">
                        {{ $t('Global.Update') }}
                      </CButton>
                    </CCardBody>
                  </CCard>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow class="border-bottom mb-4">
            <CCol col="12" md="6" xl="4">
              <div class="p-3">
                <h3 class="font-weight-bold">順豐速運 SF-Express</h3>
                <p>簽約服務，啟用時請先確定已與物流供應商取得串接資訊。</p>
                <ul class="pl-3">
                  <li>宅配服務</li>
                  <li>跨境物流</li>
                </ul>
              </div>
            </CCol>
            <CCol col="12" md="6" xl="8">
              <CCard>
                <CCardBody>
                  <div class="form-group">
                    <label for="ucfuEnable" class="d-block">啟用順豐速運</label>
                    <CSwitch id="ucfuEnable" size="sm" color="success" :checked.sync="MerchantData['sf-logistics'].Enable" />
                  </div>
                  <template v-if="MerchantData['sf-logistics'].Enable">
                    <CInput id="sfAppID" v-model="MerchantData['sf-logistics'].AppID" size="sm" type="text">
                      <template #label>
                        <label for="sfAppID" class="d-block">AppID</label>
                      </template>
                    </CInput>
                    <CInput id="sfAppKey" v-model="MerchantData['sf-logistics'].AppKey" size="sm" type="text">
                      <template #label>
                        <label for="sfAppKey" class="d-block">AppKey</label>
                      </template>
                    </CInput>
                    <CInput id="sfAppSecret" v-model="MerchantData['sf-logistics'].AppSecret" size="sm" type="text">
                      <template #label>
                        <label for="sfAppSecret" class="d-block">AppSecret</label>
                      </template>
                    </CInput>
                    <CInput id="sfSecureKey" v-model="MerchantData['sf-logistics'].SecureKey" size="sm" type="text">
                      <template #label>
                        <label for="sfSecureKey" class="d-block">AES Secret</label>
                      </template>
                    </CInput>
                    <CInput id="sfMerchantID" v-model="MerchantData['sf-logistics'].MerchantID" size="sm" type="text">
                      <template #label>
                        <label for="sfMerchantID" class="d-block">{{ $t('MerchantData.MerchantID') }}</label>
                      </template>
                    </CInput>
                    <CInput id="sfAccount" v-model="MerchantData['sf-logistics'].Account" size="sm" type="text">
                      <template #label>
                        <label for="sfAccount" class="d-block">{{ $t('MerchantData.PayMonthCard') }}</label>
                      </template>
                    </CInput>
                  </template>
                  <CCard>
                    <CCardBody class="d-flex justify-content-between p-2">
                      <CButton color="success" class="px-4" @click="SetPayCenterMerchant('sf-logistics')">
                        {{ $t('Global.Update') }}
                      </CButton>
                    </CCardBody>
                  </CCard>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow class="border-bottom mb-4">
            <CCol col="12" md="6" xl="4">
              <div class="p-3">
                <h3 class="font-weight-bold">DEPX / SKYNET</h3>
                <p>簽約服務，啟用時請先確定已與物流供應商取得串接資訊。</p>
                <ul class="pl-3">
                  <li>跨境物流</li>
                </ul>
              </div>
            </CCol>
            <CCol col="12" md="6" xl="8">
              <CCard>
                <CCardBody>
                  <div class="form-group">
                    <label for="ucfuEnable" class="d-block">啟用DPEX / SKYNET</label>
                    <CSwitch id="ucfuEnable" size="sm" color="success" :checked.sync="MerchantData['dpex-logistics'].Enable" />
                  </div>
                  <template v-if="MerchantData['dpex-logistics'].Enable">
                    <CInput id="dpexAccount" v-model="MerchantData['dpex-logistics'].Account" size="sm" type="text">
                      <template #label>
                        <label for="dpexAccount" class="d-block">{{ $t('MerchantData.Account') }}</label>
                      </template>
                    </CInput>
                    <CInput id="dpexPassword" v-model="MerchantData['dpex-logistics'].Password" size="sm" type="text">
                      <template #label>
                        <label for="dpexPassword" class="d-block">{{ $t('MerchantData.Password') }}</label>
                      </template>
                    </CInput>
                  </template>
                  <CCard>
                    <CCardBody class="d-flex justify-content-between p-2">
                      <CButton color="success" class="px-4" @click="SetPayCenterMerchant('dpex-logistics')">
                        {{ $t('Global.Update') }}
                      </CButton>
                    </CCardBody>
                  </CCard>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow class="border-bottom mb-4">
            <CCol col="12" md="6" xl="4">
              <div class="p-3">
                <h3 class="font-weight-bold">UCFuShip</h3>
                <p>簽約服務，此為華美航運OCS的跨境物流服務，啟用時請先確定已與物流供應商取得串接資訊。</p>
                <ul class="pl-3">
                  <li>跨境物流</li>
                </ul>
              </div>
            </CCol>
            <CCol col="12" md="6" xl="8">
              <CCard>
                <CCardBody>
                  <div class="form-group">
                    <label for="ucfuEnable" class="d-block">啟用UCFuShip</label>
                    <CSwitch id="ucfuEnable" size="sm" color="success" :checked.sync="MerchantData['ucfuship-logistics'].Enable" />
                  </div>
                  <template v-if="MerchantData['ucfuship-logistics'].Enable">
                    <CInput id="ucfuLogisticsAccount" v-model="MerchantData['ucfuship-logistics'].Account" size="sm" type="text">
                      <template #label>
                        <label for="ucfuLogisticsAccount" class="d-block">{{ $t('MerchantData.Account') }}</label>
                      </template>
                    </CInput>
                    <CInput id="ucfuLogisticsSecureKey" v-model="MerchantData['ucfuship-logistics'].SecureKey" size="sm" type="text">
                      <template #label>
                        <label for="unitLogisticsSecureKey" class="d-block">{{ $t('MerchantData.SecureKey') }}</label>
                      </template>
                    </CInput>
                  </template>
                  <CCard>
                    <CCardBody class="d-flex justify-content-between p-2">
                      <CButton color="success" class="px-4" @click="SetPayCenterMerchant('ucfuship-logistics')">
                        {{ $t('Global.Update') }}
                      </CButton>
                    </CCardBody>
                  </CCard>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow class="border-bottom mb-4">
            <CCol col="12" md="6" xl="4">
              <div class="p-3">
                <h3 class="font-weight-bold">真站電商倉儲物流</h3>
                <p>啟用並填入串接資訊後即可使用服務。</p>
                <ul class="pl-3">
                  <li>配送方式: 超商取貨(7-11/全家/萊爾富/OK)、順豐速運、中華郵政、黑貓宅配</li>
                </ul>
              </div>
            </CCol>
            <CCol col="12" md="6" xl="8">
              <CCard>
                <CCardBody>
                  <div class="form-group">
                    <label for="jenjanEnable" class="d-block">啟用真站電商倉儲物流</label>
                    <CSwitch id="jenjanEnable" size="sm" color="success" :checked.sync="MerchantData['jenjan-logistics'].Enable" />
                  </div>
                  <template v-if="MerchantData['jenjan-logistics'].Enable">
                    <CInput id="jenjanLogisticsAccessToken" v-model="MerchantData['jenjan-logistics'].MerchantID" size="sm" type="text">
                      <template #label>
                        <label for="jenjanLogisticsAccessToken" class="d-block">{{ $t('MerchantData.PublicKey') }}</label>
                      </template>
                    </CInput>
                    <CInput id="jenjanLogisticsPublicKey" v-model="MerchantData['jenjan-logistics'].PublicKey" size="sm" type="text">
                      <template #label>
                        <label for="jenjanLogisticsPublicKey" class="d-block">{{ $t('MerchantData.SecureKey') }}</label>
                      </template>
                    </CInput>
                    <CInput id="jenjanLogisticsPrivateKey" v-model="MerchantData['jenjan-logistics'].PrivateKey" size="sm" type="text">
                      <template #label>
                        <label for="jenjanLogisticsPrivateKey" class="d-block">{{ $t('MerchantData.AccessToken') }}</label>
                      </template>
                    </CInput>
                  </template>
                  <CCard>
                    <CCardBody class="d-flex justify-content-between p-2">
                      <CButton color="success" class="px-4" @click="SetPayCenterMerchant('jenjan-logistics')">
                        {{ $t('Global.Update') }}
                      </CButton>
                    </CCardBody>
                  </CCard>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
<!--          <CRow class="border-bottom mb-4">-->
<!--            <CCol col="12" md="6" xl="4">-->
<!--              <div class="p-3">-->
<!--                <h3 class="font-weight-bold">全家(日翊)冷藏冷凍物流</h3>-->
<!--                <p>簽約服務，此為全家便利商店的冷藏/冷凍配送服務，啟用時請先確定已與物流供應商取得串接資訊。</p>-->
<!--                <ul class="pl-3">-->
<!--                  <li>配送方式: 全家超商取貨</li>-->
<!--                </ul>-->
<!--              </div>-->
<!--            </CCol>-->
<!--            <CCol col="12" md="6" xl="8">-->
<!--              <CCard>-->
<!--                <CCardBody>-->
<!--                  {{  $t('MerchantData.ComingSoon') }}-->
<!--                </CCardBody>-->
<!--              </CCard>-->
<!--            </CCol>-->
<!--          </CRow>-->
        </CTab>
        <CTab :title="$t('MerchantData.EInvoice')">
          <template #title>
            {{ $t('MerchantData.EInvoice') }}
          </template>
          <CRow class="border-bottom mb-4">
            <CCol col="12" md="6" xl="4">
              <div class="p-3">
                <h3 class="font-weight-bold">光貿電子發票 Amego</h3>
                <p>啟用並填入串接資訊後即可使用服務。<br><a href="https://invoice.amego.tw/login" target="_blank">註冊新會員及申請電子發票請按此</a></p>
              </div>
            </CCol>
            <CCol col="12" md="6" xl="8">
              <CCard>
                <CCardBody>
                  <div class="form-group">
                    <label for="AmegoEinvoiceEnable" class="d-block">啟用光貿電子發票</label>
                    <CSwitch id="AmegoEinvoiceEnable" size="sm" color="success" :checked.sync="MerchantData['amego-einvoice'].Enable" />
                  </div>
                  <template v-if="MerchantData['amego-einvoice'].Enable">
                    <CInput id="AmegoEinvoiceAccount" v-model="MerchantData['amego-einvoice'].Account" size="sm" type="text">
                      <template #label>
                        <label for="AmegoEinvoiceAccount" class="d-block">
                          統一編號
                        </label>
                      </template>
                    </CInput>
                    <CInput id="AmegoEinvoicePrivateKey" v-model="MerchantData['amego-einvoice'].PrivateKey" size="sm" type="text">
                      <template #label>
                        <label for="AmegoEinvoicePrivateKey" class="d-block">
                          App Key
                        </label>
                      </template>
                    </CInput>
                  </template>
                  <CCard>
                    <CCardBody class="d-flex justify-content-between p-2">
                      <CButton color="success" class="px-4" @click="SetPayCenterMerchant('amego-einvoice')">
                        {{ $t('Global.Update') }}
                      </CButton>
                    </CCardBody>
                  </CCard>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow class="border-bottom mb-4">
            <CCol col="12" md="6" xl="4">
              <div class="p-3">
                <h3 class="font-weight-bold">綠界科技 ECPay</h3>
                <p>綠界科技同時集成金物流與電子發票，適合一般賣家直接使用。</p>
              </div>
            </CCol>
            <CCol col="12" md="6" xl="8">
              <CCard>
                <CCardBody>
                  <div class="form-group">
                    <label for="ecpayEinvoiceEnable" class="d-block">啟用綠界電子發票</label>
                    <CSwitch id="ecpayEinvoiceEnable" size="sm" color="success" :checked.sync="MerchantData['ecpay-einvoice'].Enable" />
                  </div>
                  <template v-if="MerchantData['ecpay-einvoice'].Enable">
                    <CInput id="ecpayEinvoiceMerchantID" v-model="MerchantData['ecpay-einvoice'].MerchantID" size="sm" type="text">
                      <template #label>
                        <label for="ecpayEinvoiceMerchantID" class="d-block">{{ $t('MerchantData.MerchantID') }}</label>
                      </template>
                    </CInput>
                    <CInput id="ecpayEinvoiceHashKey" v-model="MerchantData['ecpay-einvoice'].HashKey" size="sm" type="text">
                      <template #label>
                        <label for="ecpayEinvoiceHashKey" class="d-block">{{ $t('MerchantData.HashKey') }}</label>
                      </template>
                    </CInput>
                    <CInput id="ecpayEinvoiceHashIV" v-model="MerchantData['ecpay-einvoice'].HashIV" size="sm" type="text">
                      <template #label>
                        <label for="ecpayEinvoiceHashIV" class="d-block"> {{ $t('MerchantData.HashIV') }}</label>
                      </template>
                    </CInput>
                  </template>
                  <CCard>
                    <CCardBody class="d-flex justify-content-between p-2">
                      <CButton color="success" class="px-4" @click="SetPayCenterMerchant('ecpay-einvoice')">
                        {{ $t('Global.Update') }}
                      </CButton>
                    </CCardBody>
                  </CCard>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow class="border-bottom mb-4">
            <CCol col="12" md="6" xl="4">
              <div class="p-3">
                <h3 class="font-weight-bold">易發票 ezReceipt (2.0)</h3>
                <p>啟用並填入串接資訊後即可使用服務<br><span class="text-danger">若您的易發票是於2021-08-20後的新申請的商戶，請使用服務。</span></p>
              </div>
            </CCol>
            <CCol col="12" md="6" xl="8">
              <CCard>
                <CCardBody>
                  <div class="form-group">
                    <label for="ezreceiptEinvoiceEnable" class="d-block">啟用易發票2.0</label>
                    <CSwitch id="ezreceiptEinvoiceEnable" size="sm" color="success" :checked.sync="MerchantData['ezreceiptv2-einvoice'].Enable" />
                  </div>
                  <template v-if="MerchantData['ezreceiptv2-einvoice'].Enable">
                    <CInput id="ezreceiptEinvoiceMerchantID" v-model="MerchantData['ezreceiptv2-einvoice'].MerchantID" size="sm" type="text">
                      <template #label>
                        <label for="ezreceiptEinvoiceMerchantID" class="d-block">{{ $t('MerchantData.MerchantID') }}(StID)</label>
                      </template>
                    </CInput>
                    <CInput id="ezreceiptEinvoiceAccount" v-model="MerchantData['ezreceiptv2-einvoice'].Account" size="sm" type="text">
                      <template #label>
                        <label for="ezreceiptEinvoiceAccount" class="d-block">{{ $t('MerchantData.Account') }}(AccName)</label>
                      </template>
                    </CInput>
                    <CInput id="ezreceiptEinvoicePassword" v-model="MerchantData['ezreceiptv2-einvoice'].Password" size="sm" type="text">
                      <template #label>
                        <label for="ezreceiptEinvoicePassword" class="d-block"> {{ $t('MerchantData.Password') }}(AccPwd)</label>
                      </template>
                    </CInput>
                    <CInput id="ezreceiptEinvoicePrivateKey" v-model="MerchantData['ezreceiptv2-einvoice'].PrivateKey" size="sm" type="text">
                      <template #label>
                        <label for="ezreceiptEinvoicePrivateKey" class="d-block"> {{ $t('MerchantData.PrivateKey') }}(AppKey)</label>
                      </template>
                    </CInput>
                  </template>
                  <CCard>
                    <CCardBody class="d-flex justify-content-between p-2">
                      <CButton color="success" class="px-4" @click="SetPayCenterMerchant('ezreceiptv2-einvoice')">
                        {{ $t('Global.Update') }}
                      </CButton>
                    </CCardBody>
                  </CCard>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow class="border-bottom mb-4">
            <CCol col="12" md="6" xl="4">
              <div class="p-3">
                <h3 class="font-weight-bold">易發票 ezReceipt (舊平台)</h3>
                <p>啟用並填入串接資訊後即可使用服務<br><span class="text-danger">若您的易發票是於2021-08-20後的新申請的商戶，請使用2.0版本的服務。</span></p>
              </div>
            </CCol>
            <CCol col="12" md="6" xl="8">
              <CCard>
                <CCardBody>
                  <div class="form-group">
                    <label for="ezreceiptEinvoiceEnable" class="d-block">啟用易發票</label>
                    <CSwitch id="ezreceiptEinvoiceEnable" size="sm" color="success" :checked.sync="MerchantData['ezreceipt-einvoice'].Enable" />
                  </div>
                  <template v-if="MerchantData['ezreceipt-einvoice'].Enable">
                    <CInput id="ezreceiptEinvoiceMerchantID" v-model="MerchantData['ezreceipt-einvoice'].MerchantID" size="sm" type="text">
                      <template #label>
                        <label for="ezreceiptEinvoiceMerchantID" class="d-block">{{ $t('MerchantData.MerchantID') }}(StID)</label>
                      </template>
                    </CInput>
                    <CInput id="ezreceiptEinvoiceAccount" v-model="MerchantData['ezreceipt-einvoice'].Account" size="sm" type="text">
                      <template #label>
                        <label for="ezreceiptEinvoiceAccount" class="d-block">{{ $t('MerchantData.Account') }}(AccName)</label>
                      </template>
                    </CInput>
                    <CInput id="ezreceiptEinvoicePassword" v-model="MerchantData['ezreceipt-einvoice'].Password" size="sm" type="text">
                      <template #label>
                        <label for="ezreceiptEinvoicePassword" class="d-block"> {{ $t('MerchantData.Password') }}(AccPwd)</label>
                      </template>
                    </CInput>
                    <CInput id="ezreceiptEinvoicePrivateKey" v-model="MerchantData['ezreceipt-einvoice'].PrivateKey" size="sm" type="text">
                      <template #label>
                        <label for="ezreceiptEinvoicePrivateKey" class="d-block"> {{ $t('MerchantData.PrivateKey') }}(AppKey)</label>
                      </template>
                    </CInput>
                  </template>
                  <CCard>
                    <CCardBody class="d-flex justify-content-between p-2">
                      <CButton color="success" class="px-4" @click="SetPayCenterMerchant('ezreceipt-einvoice')">
                        {{ $t('Global.Update') }}
                      </CButton>
                    </CCardBody>
                  </CCard>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CTab>
        <CTab :title="$t('MerchantData.Message')">
          <template #title>
            {{ $t('MerchantData.Message') }}
          </template>
          <CRow class="border-bottom mb-4">
            <CCol col="12" md="6" xl="4">
              <div class="p-3">
                <h3 class="font-weight-bold">Every8D</h3>
                <p>簽約服務，簡訊發送服務供應商，支援多種簡訊格式。</p>
              </div>
            </CCol>
            <CCol col="12" md="6" xl="8">
              <CCard>
                <CCardBody>
                  <div class="form-group">
                    <label for="every8dMessageEnable" class="d-block">啟用Every8D簡訊傳送服務</label>
                    <CSwitch id="every8dMessageEnable" size="sm" color="success" :checked.sync="MerchantData['every8d-message'].Enable" />
                  </div>
                  <template v-if="MerchantData['every8d-message'].Enable">
                    <CInput id="every8dMessageAccount" v-model="MerchantData['every8d-message'].Account" size="sm" type="text">
                      <template #label>
                        <label for="every8dMessageAccount" class="d-block">{{ $t('MerchantData.Account') }}</label>
                      </template>
                    </CInput>
                    <CInput id="every8dMessagePassword" v-model="MerchantData['every8d-message'].Password" size="sm" type="text">
                      <template #label>
                        <label for="every8dMessagePassword" class="d-block">{{ $t('MerchantData.Password') }}</label>
                      </template>
                    </CInput>
                  </template>
                  <CCard>
                    <CCardBody class="d-flex justify-content-between p-2">
                      <CButton color="success" class="px-4" @click="SetMerchant('every8d-message')">
                        {{ $t('Global.Update') }}
                      </CButton>
                    </CCardBody>
                  </CCard>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow class="border-bottom mb-4">
            <CCol col="12" md="6" xl="4">
              <div class="p-3">
                <h3 class="font-weight-bold">LINE Notify</h3>
                <p>綁定LINE Notify後，系統即可將通知內容透過LINE群組或個人訊息內。目前支援以下通知：</p>
                <ul class="pl-3">
                  <li>顧客下單通知</li>
                </ul>
              </div>
            </CCol>
            <CCol col="12" md="6" xl="8">
              <CCard>
                <CCardBody>
                  <div class="form-group">
                    <label for="LineNotifyEnable" class="d-block">啟用LINE Notify</label>
                    <CSwitch id="LineNotifyEnable" size="sm" color="success" :checked.sync="MerchantData['line-notify'].Enable" />
                  </div>
                  <template v-if="MerchantData['line-notify'].Enable">
                    <CInput id="LineNotifyAccessToken" v-model="MerchantData['line-notify'].AccessToken" size="sm" type="text" disabled>
                      <template #label>
                        <label for="LineNotifyAccessToken" class="d-block">ACCESS_TOKEN</label>
                      </template>
                    </CInput>
                    <CInput id="LineNotifyClientID" v-model="MerchantData['line-notify'].ClientID" size="sm" type="text">
                      <template #label>
                        <label for="LineNotifyClientID" class="d-block">Client ID</label>
                      </template>
                    </CInput>
                    <CInput id="LineNotifyClientSecret" v-model="MerchantData['line-notify'].ClientSecret" size="sm" type="text">
                      <template #label>
                        <label for="LineNotifyClientSecret" class="d-block">Client Secret</label>
                      </template>
                    </CInput>
                  </template>
                  <CButton @click="AccessTokenGen" color="info" class="px-4" style="margin-right: 0.5rem;" :disabled="!MerchantData['line-notify'].Enable">生成</CButton>
                  <CButton color="success" class="px-4" @click="SetMerchant('line-notify')" :disabled="!MerchantData['line-notify'].Enable">
                    {{ $t('Global.Update') }}
                  </CButton>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow class="border-bottom mb-4">
            <CCol col="12" md="6" xl="4">
              <div class="p-3">
                <h3 class="font-weight-bold">LINE Messaging API</h3>
                <p>與LINE官方帳號連動後可使用回應機器人與系統訊息推送連動。</p>
              </div>
            </CCol>
            <CCol col="12" md="6" xl="8">
              <CCard>
                <CCardBody>
                  <div class="form-group">
                    <label for="LineOfficialAccountEnable" class="d-block">啟用LINE Messaging API</label>
                    <CSwitch id="LineOfficialAccountEnable" size="sm" color="success" :checked.sync="MerchantData['line-messaging-api'].Enable" />
                  </div>
                  <template v-if="MerchantData['line-messaging-api'].Enable">
                    <CInput id="LineOfficialAccountAccountID" v-model="MerchantData['line-messaging-api'].AccountID" size="sm" type="text">
                      <template #label>
                        <label for="LineOfficialAccountAccountID" class="d-block">官方帳號ID</label>
                      </template>
                    </CInput>
                    <CInput id="LineOfficialAccountChannelID" v-model="MerchantData['line-messaging-api'].ChannelID" size="sm" type="text">
                      <template #label>
                        <label for="LineOfficialAccountChannelID" class="d-block">Channel ID</label>
                      </template>
                    </CInput>
                    <CInput id="LineOfficialAccountChannelSecret" v-model="MerchantData['line-messaging-api'].ChannelSecret" size="sm" type="text">
                      <template #label>
                        <label for="LineOfficialAccountChannelSecret" class="d-block">Channel Secret</label>
                      </template>
                    </CInput>
                    <CInput id="LineOfficialAccountChannelAccessToken" v-model="MerchantData['line-messaging-api'].ChannelAccessToken" size="sm" type="text">
                      <template #label>
                        <label for="LineOfficialAccountChannelAccessToken" class="d-block">Channel Access Token</label>
                      </template>
                    </CInput>
                  </template>
                  <CButton color="success" class="px-4" @click="SetMerchant('line-messaging-api')" :disabled="!MerchantData['line-messaging-api'].Enable">
                    {{ $t('Global.Update') }}
                  </CButton>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CTab>
        <CTab :title="$t('MerchantData.Media')">
          <template #title>
            {{ $t('MerchantData.Media') }}
          </template>
          <CRow class="border-bottom mb-4">
            <CCol col="12" md="6" xl="4">
              <div class="p-3">
                <h3 class="font-weight-bold">Vimeo</h3>
                <p>強大並有許多個人化影片設定的影音服務平台。</p>
              </div>
            </CCol>
            <CCol col="12" md="6" xl="8">
              <CCard>
                <CCardBody>
                  <div class="form-group">
                    <label for="vimeoEnable" class="d-block">啟用Vimeo服務</label>
                    <CSwitch id="vimeoEnable" size="sm" color="success" :checked.sync="MerchantData['vimeo'].Enable" />
                  </div>
                  <template v-if="MerchantData['vimeo'].Enable">
                    <CInput id="vimeoClientID" v-model="MerchantData['vimeo'].MerchantID" size="sm" type="text">
                      <template #label>
                        <label for="vimeoClientID" class="d-block">{{ $t('MerchantData.ClientID') }}</label>
                      </template>
                    </CInput>
                    <CInput id="vimeoClientSecret" v-model="MerchantData['vimeo'].SecureKey" size="sm" type="text">
                      <template #label>
                        <label for="vimeoClientSecret" class="d-block">{{ $t('MerchantData.SecureKey') }}</label>
                      </template>
                    </CInput>
                    <CInput id="vimeoAccessToken" v-model="MerchantData['vimeo'].PrivateKey" size="sm" type="text">
                      <template #label>
                        <label for="vimeoAccessToken" class="d-block">{{ $t('MerchantData.AccessToken') }}</label>
                      </template>
                    </CInput>
                  </template>
                  <CCard>
                    <CCardBody class="d-flex justify-content-between p-2">
                      <CButton color="success" class="px-4" @click="SetMerchant('vimeo')">
                        {{ $t('Global.Update') }}
                      </CButton>
                    </CCardBody>
                  </CCard>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CTab>
        <CTab :title="$t('MerchantData.CMS')">
          <template #title>
            {{ $t('MerchantData.CMS') }}
          </template>
          <CRow class="border-bottom mb-4">
            <CCol col="12" md="6" xl="4">
              <div class="p-3">
                <h3 class="font-weight-bold">WordPress Rest API</h3>
                <p>自由開源的部落格軟體及內容管理系統，並具有外掛程式架構和模板系統。</p>
              </div>
            </CCol>
            <CCol col="12" md="6" xl="8">
              <CCard>
                <CCardBody>
                  <div class="form-group">
                    <label for="vimeoEnable" class="d-block">啟用WP Rest API服務</label>
                    <CSwitch id="vimeoEnable" size="sm" color="success" :checked.sync="MerchantData['wordpress-cms'].Enable" />
                  </div>
                  <template v-if="MerchantData['wordpress-cms'].Enable">
                    <CInput id="wpcmsAccount" v-model="MerchantData['wordpress-cms'].Account" size="sm" type="text">
                      <template #label>
                        <label for="wpcmsAccount" class="d-block">{{ $t('MerchantData.Account') }}</label>
                      </template>
                    </CInput>
                    <CInput id="wpcmsPassword" v-model="MerchantData['wordpress-cms'].Password" size="sm" type="text">
                      <template #label>
                        <label for="wpcmsPassword" class="d-block">{{ $t('MerchantData.Password') }}</label>
                      </template>
                    </CInput>
                    <CInput id="wpcmsHost" v-model="MerchantData['wordpress-cms'].NotifyURL" size="sm" type="text">
                      <template #label>
                        <label for="wpcmsHost" class="d-block">{{ $t('MerchantData.Host') }}</label>
                      </template>
                    </CInput>
                  </template>
                  <CCard>
                    <CCardBody class="d-flex justify-content-between p-2">
                      <CButton color="success" class="px-4" @click="SetMerchant('wordpress-cms')">
                        {{ $t('Global.Update') }}
                      </CButton>
                    </CCardBody>
                  </CCard>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CTab>
        <CTab :title="$t('MerchantData.OAuth')">
          <template #title>
            {{ $t('MerchantData.OAuth') }}
          </template>
          <CAlert color="info">
            目前未開放自行設定，若需設定請聯絡業務窗口。
          </CAlert>
        </CTab>
      </CTabs>
    </CContainer>
  </main>
</template>

<route>
{
  "meta": {
    "label": "第三方服務"
  }
}
</route>

<script>
export default {
  name: 'Integration',
  layout: 'manage',
  components: {
  },
  data () {
    return {
      Loading: false,
      Submit: false,
      MerchantData: {
        'ecpay': {
          Taxonomy: 'Payment'
        },
        'newebpay': {
          Taxonomy: 'Payment'
        },
        'tspgpay': {
          Taxonomy: 'Payment'
        },
        'focaspay': {
          Taxonomy: 'Payment'
        },
        'tappay': {
          Taxonomy: 'Payment'
        },
        'vi_momopay': {
          Taxonomy: 'Payment'
        },
        'unipay': {
          Taxonomy: 'Payment'
        },
        'ecpay-logistics': {
          Taxonomy: 'Logistics'
        },
        'hct-logistics': {
          Taxonomy: 'Logistics'
        },
        'fami-logistics': {
          Taxonomy: 'Logistics'
        },
        'ucfuship-logistics': {
          Taxonomy: 'Logistics'
        },
        'jenjan-logistics': {
          Taxonomy: 'Logistics'
        },
        'kerrytj-logistics': {
          Taxonomy: 'Logistics'
        },
        'sf-logistics': {
          Taxonomy: 'Logistics'
        },
        'dpex-logistics': {
          Taxonomy: 'Logistics'
        },
        'ecpay-einvoice': {
          Taxonomy: 'EInvoice'
        },
        'ezreceipt-einvoice': {
          Taxonomy: 'EInvoice'
        },
        'ezreceiptv2-einvoice': {
          Taxonomy: 'EInvoice'
        },
        'amego-einvoice': {
          Taxonomy: 'EInvoice'
        },
        'every8d-message': {
          Taxonomy: 'Message'
        },
        'vimeo': {
          Taxonomy: 'Video'
        },
        'line-notify': {
          Taxonomy: 'Message'
        },
        'line-messaging-api': {
          Taxonomy: 'Message'
        },
        'wordpress-cms': {
          Taxonomy: 'CMS'
        }
      },
      noItemsView: {
        noResults: this.$t('Global.NoResults'),
        noItems: this.$t('Global.NoItems')
      }
    }
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.$store.dispatch('InnerRequest', {
          url: '/system/get/MerchantData',
          method: 'post'
        }).then(({data}) => {
          this.MerchantData = {
            ...this.MerchantData,
            ...data
          }
          // console.log(this.MerchantData)
          return true
        }).catch((err) => {
          throw err
        })
      ]).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    SetPayCenterMerchant(system) {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/merchant/pay/set',
        method: 'post',
        data: {
         ...this.MerchantData[system],
          System: system
        }
      }).then(() => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.System/ModifySuccess'),
          type: 'success'
        })
        this.Init()
      }).catch((err) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.System/ModifyFail') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    SetMerchant(system) {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/merchant/set',
        method: 'post',
        data: {
          ...this.MerchantData[system],
          System: system
        }
      }).then(() => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.System/ModifySuccess'),
          type: 'success'
        })
        this.Init()
      }).catch((err) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.System/ModifyFail') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    AccessTokenGen() {
      if (!this.MerchantData['line-notify'].ClientID || !this.MerchantData['line-notify'].ClientSecret) {
        return this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: 'Client ID/Client Secret 不得為空',
          type: 'error'
        })
      }
      if (!this.MerchantData['line-notify'].AccessToken) this.SetMerchant('line-notify')
      const csrfToken = localStorage.getItem('XX-CSRF-Token')
      const RedirectUrl = `https://${window.location.hostname}/api/notify/LineNotify/Subscribe`
      const ClientId = this.MerchantData['line-notify'].ClientID
      window.location.href = `https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=${ClientId}&redirect_uri=${RedirectUrl}&scope=notify&state=${csrfToken}&response_mode=form_post`
    }
  }
}
</script>
